import React from "react";
import sakura from "../../img/sakura2.png";
import styled from "styled-components";
import { device } from '../MainContents/Style/StyleDevice';
const DivTop = styled.div`
  width: 50%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 10px;
  @media ${device.mobile} {
    width: 100%;
  }
`

const SuccessList = props => {
const {college, college_faculty, school, name, comment} = props; 
  return (
    <DivTop>
        <p className="font-bold"><span className="text-lg text-red-700">{college}</span>&nbsp;<span>{college_faculty}</span></p>
        <p className="font-bold">出身高校:&nbsp;<span>{school}</span></p>
        <p className="font-bold">{name}&ensp;さん</p>

        <p className="mt-2"><img src={sakura} style={{display: 'inline',width: '23px'}} />&nbsp;合格体験記</p>
        <hr />
        <p>{comment}</p>
    </DivTop>
  );
}

export default SuccessList;
