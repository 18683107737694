import React from 'react';
import styled from 'styled-components';

const TextCourse = styled.p`
    padding: 5px;
    margin-bottom: 100px;
`
const CourseFeature = props => {
    const {supported_level, text} = props; 
    return(
        <>
        <h6 className="mt-5 pl-2 sm:text-xl text-lg font-bold pb-2 border-l-4 border-red-300" style={{ borderColor: '#D491AC',width: '150px' }}>
            コースの特徴
        </h6>
        <div className="mt-5 mb-5">
            {/* supported_level　の　値がtrueのときだけ表示する */}
            {supported_level && (
                <>
                <span className="pr-2 rounded-full bg-black text-white" style={{padding: '1px 4px 1px 4px'}}>対応レベル</span>
                <span className="font-bold">全国模試総合偏差値63以上</span>
                </>
            )}
            <TextCourse>{text}</TextCourse>
        </div>
        </>
    );
}
export default CourseFeature;