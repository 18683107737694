import React from 'react';
import styled from 'styled-components';

const MaruBotan = styled.span`
    color: rgb(255, 255, 255);
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: 22px;
    padding: 3px 1px 5px 7px;
`
const CourseTamplete = props => {
    const {course, courseName, college , bgColor} = props; 
    return(
        <>
        <div className="mt-5">
        <MaruBotan style={{backgroundColor: bgColor}}>{course}</MaruBotan><span className="font-bold text-2xl ml-3">{courseName}</span>
            <p className="mt-2">目標大学<span className="ml-2 mr-1">{college}</span>など</p>
        </div>
        <div>

        </div>
        </>
    );
}

export default CourseTamplete;