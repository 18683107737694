import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../../img/logo200.png";
// import STEMONLogo from '../../img/Main-logo.png';


const Footer = () => {
    var currentTime = new Date();
    var year = currentTime.getFullYear()
  return (
    <div className="mt-5">
      <footer className="text-gray-600 body-font">
          <div className="bg-gray-100 text-center">
            <div className='flex title-font font-medium items-center md:justify-start justify-center text-gray-900 mx-auto w-1/2'>
              <img src={logo} alt="Top Image" className="mt-5 mx-auto" />
            </div>

            <p className="mt-2 text-sm text-gray-500">〒780-0822 高知市はりまや町3-7-8</p>
            <p className="mt-2 text-sm text-gray-500">TEL 088-883-1065</p>
            <div className="container mx-auto  py-4 px-5 flex flex-wrap flex-col sm:flex-row">

              <p className="text-gray-500 text-sm text-center mx-auto">© {year} Tailblocks —
                <a href="https://twitter.com/knyttneve" rel="noopener noreferrer" className="text-gray-600 ml-1" target="_blank">@knyttneve</a>
              </p>
            </div>
          </div>
      </footer>
    </div>
  );
}
export default Footer;