import React from "react";
import styled from 'styled-components';
import CourseTamplete from "./CourseTamplete";
import CourseFeature from "./CourseFeature";
import { device } from '../MainContents/Style/StyleDevice';
const DivTop = styled.div`
    width: 90%;
    margin: auto;
    padding: 150px 0 100px;
    // background: url(https://parsonalnavi.com/wp-content/themes/terakoya/img/bg-sq.png) 0 0 repeat;
    height: auto;
    @media ${device.mobile} {
        width: 95%;
    }
`;

const CourseKokuritu = styled.h1`
    border-color: #9b003f;
    width: 500px;
    margin-top: 50px;
    margin-bottom: 50px;
    @media ${device.mobile} {
        width: 370px;
    }
`

const CourseShiritu = styled.h1`
    border-color: #00c3fe;
    width: 500px;
    margin-top: 50px;
    margin-bottom: 50px;
    @media ${device.mobile} {
        width: 370px;
    }
`

const CourseBackGround = styled.div`
    background: rgb(251, 251, 251);
`
const Course = () => {
    return (
        <DivTop>
            <h2 className="text-center sm:text-4xl text-3xl text-red-900 font-bold">コース紹介</h2>
            <div className="">
   
                <CourseKokuritu className="mt-5 pl-2 sm:text-4xl text-3xl font-bold pb-2 border-b-4 border-red-600">
                    国公立大学受験コース
                </CourseKokuritu>

                <CourseTamplete course={'A3'} courseName={'文系選抜クラス'} college={'東京大学・京都大学・一橋大学・大阪大学'} bgColor={'#9b003f'} />
                <CourseTamplete course={'B3'} courseName={'理系選抜クラス'} college={'東京大学・京都大学・大阪大学・国公立大学医学部'} bgColor={'#9b003f'}/>
                <CourseFeature 
                   supported_level={true} 
                   text={'クラスの特質上、他のクラスと比べて少人数で編成されており、生徒に要求する努力も必然的に高くなっています。「共通テスト」での目標得点率は85 %以上。そのために6教科8科目の演習を徹底的におこない、総合力の完成を目指します。二次対策については、志望校に応じて難度の高い間題演習をおこなう中で、受験に必要な深い思考カ・論理力を養成します。'}
                />

                <CourseBackGround>
                    <CourseTamplete course={'A2'} courseName={'文系二次学力強化クラス'} college={'九州大学・神戸大学・横浜国立大学・大阪公立大学・岡山大学・広島大学'} bgColor={'#9b003f'}/>
                    <CourseTamplete course={'B2'} courseName={'理系二次学力強化クラス'} college={'九州大学・北海道大学・筑波大学・神戸大学・岡山大学・広島大学'} bgColor={'#9b003f'}/>
                    <CourseFeature 
                        supported_level={false}
                        text={'共通テストで高得点の獲得が第一条件。ただし、このレベルの大学は受験生に対して高い二次学力も要求されます。そのため、このクラスでは共通テストに必要な基礎学力が完成していることを前提として、二次学力の強化に重点を置いた授業を展開します。'} 
                    />

                </CourseBackGround>



                <CourseTamplete course={'A1'} courseName={'文系共通テスト錬成クラス'} college={'高知大学・高知県立大学(看護)・高知工科大学(経済・マネジメントA方式)・愛媛大学・香川大学'} bgColor={'#9b003f'}/>
                <CourseTamplete course={'B1'} courseName={'理系共通テスト錬成クラス'} college={'高知大学・高知工科大学・徳島大学・愛媛大学・香川大学'} bgColor={'#9b003f'}/>
                <CourseFeature
                    supported_level={false}
                    text={'地方国公立大学のように共通テスト配点の高い大学に合格するためには、共通テストで十分な得点をとることが重要となります。そのため、共通テストの得点率60%以上を目指し、その上で各大学の個別試験に対応した二次学力の充実を図ります。'}
                />

                <CourseBackGround>
                    <CourseTamplete course={'S1'} courseName={'文系アラカルト受験クラス'} college={'高知県立大学(文化/社会福祉)・高知工科大学(経済・マネジメントB方式)・横浜市立大学(国際商学B方式)・北九州市立大学' } bgColor={'#9b003f'}/>
                    <CourseFeature 
                        supported_level={false}
                        text={'共通テストの受験教科を、英・国・社または英・国・数に絞り、十分な演習量で着実に合格ラインを突破するための実力をつけていきます。二次科目は、それぞれの目標大学に即した演習をおこないます。入試科目が少なければ当然合格ボーダーも上がります。新課程になっても"１点"を争う入試になると予想されます。'} 
                    />
                </CourseBackGround>

                <CourseShiritu className="mt-5 pl-2 sm:text-4xl text-3xl font-bold pb-2 border-b-4 border-green-600">
                    私立大学受験コース
                </CourseShiritu>
                <CourseTamplete course={'C3'} courseName={'文系選抜クラス'} college={'早稲田大学・慶応義塾大学・上智大学'} bgColor={'#00c3fe'}/>
                <CourseFeature
                    supported_level={true}
                    text={'早稲田・慶應などを第一目標に、私立大学最難関校にチャレンジ。豊富な知識、それにともなう応用力を養い、さらに読解カ・思考力をトップに引き上げます。少数精鋭で緻密な授業を実施し、日々の細かな添削指導により弱点を完全克服。3教科入試のプロを育てます。'}
                />

                <CourseBackGround>
                    <CourseTamplete course={'C2'} courseName={'文系応用力完成クラス'} college={'明治大学・青山学院大学・立教大学・中央大学・法政大学 同志社大学・立命館大学・関西学院大学・関西大学'} bgColor={'#00c3fe'}/>
                    <CourseTamplete course={'D2'} courseName={'理系応用力完成クラス'} college={'東京理科大学・同志社大学・立命館大学・関西大学・京都薬科大学'} bgColor={'#00c3fe'}/>
                    <CourseFeature
                        supported_level={false}
                        text={'関東・関西の私立上位校合格のために必要な応用カ・実戦力を育成します。文系は英語・国語、理系は英語・数学・理科の実戦演習を通して応用力の完成を目指します。'}
                    />
                </CourseBackGround>
                <CourseTamplete course={'C1'} courseName={'文系基礎力完成クラス'} college={'東洋大学・専修大学・駒澤大学・日本大学・近畿大学・龍谷大学・松山大学'} bgColor={'#00c3fe'}/>
                <CourseTamplete course={'D1'} courseName={'理系基礎力完成クラス'} college={'近畿大学・大阪工業大学・岡山理科大学・徳島文理大学'} bgColor={'#00c3fe'}/>
                <CourseFeature
                    supported_level={false}
                    text={'大学入試に必要な教科を基礎から鍛え上げます。英語では語彙力や文法力を鍛えながら標準レベルの間題を解く力を身につけます。国語や数学も基礎レベルから順にマスターしていけるよう、徹底指導します。'}
                />
            </div>

        </DivTop>
    );
}

export default Course;