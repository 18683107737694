import React from 'react';
import styled from 'styled-components';
import tosayobi_student from "../../img/image_top3.jpeg";
import kawai from "../../img/eizou.jpg";

const DivTop = styled.div`
    width: 100%;
    margin: auto;
    padding: 150px 0 100px;
    background: #f4f6f8;
    height: auto;
`;


// const Iframe = styled.iframe`
//     width: 90%;
//     height :400px;
// `;

/**
 * 
 * @returns 候補バックグランド
 * background: linear-gradient(138deg, rgba(104,217,218,1) 0%, rgba(17,194,193,1) 66%, rgba(72,203,188,1) 88%);
 */
const Approach = () => {
    return (
        <div style={{background : '#1ac067'}}>
            <DivTop>
            <section className="text-gray-600 body-font">
            <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
                <img className="object-cover object-center rounded" alt="hero" src={tosayobi_student} />
                </div>  
                <div className="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center text-cente">
                    {/* <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-red-700"><span className="sm:text-lg text-sm">高卒（既卒生）クラス</span> */}
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-red-700"><span className="sm:text-xl text-lg">既卒生コース</span>
                        <br className="hidden lg:inline-block"/>土佐塾予備校
                    </h1>
                  
                    {/* <div className="flex justify-center">
                        <Link to={'./Introductions'} className="inline-flex text-red-700 font-bold tracking-wider bg-white border-2 border-white py-2 px-6 focus:outline-none hover:text-white hover:bg-red-600 rounded text-xl">
                            詳しくはこちら<span className="material-symbols-outlined" style={{paddingTop: '3px'}}>chevron_right</span>
                        </Link>
                    </div> */}
                </div>
            </div>
            </section>

            <section className="text-gray-600 body-font">
                <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-red-700"><span className="sm:text-xl text-lg">現役生(高校生コース)</span>
                        <br className="hidden lg:inline-block" />河合塾マナビスはりまや校
                    </h1>
                    
                    <a href="https://www2.manavis.com/roomsearch/tabid/74/pdid/13902/Default.aspx" target="_blank"className="inline-flex text-red-700 font-bold tracking-wider bg-white border-2 border-white py-2 px-6 focus:outline-none hover:text-white hover:bg-red-600 rounded text-xl">
                        詳しくはこちら
                        <span className="material-symbols-outlined" style={{paddingTop: '3px'}}>chevron_right</span>
                    </a>
                    </div>
                    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
                    <img className="object-cover object-center rounded" alt="hero" src={kawai} />
                    </div>
                </div>
            </section>
            </DivTop>
        </div>
    );
}

export default Approach;