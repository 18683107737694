import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

function Loading() {
  const [isLoading, setIsLoading] = useState(true);
  const [cookies, setCookie] = useCookies(["accessDate"]);

  useEffect(() => {
    // クッキーからアクセス日付を取得
    const accessDate = cookies.accessDate;

    if (accessDate) {
      // 2回目以降のアクセスなのでローディング画面を非表示にする
      setIsLoading(false);
    } else {
      // 初回アクセスなのでアクセス日付をクッキーに保存
      setCookie("accessDate", new Date().toISOString());
    }
  }, [cookies]);

  return isLoading ? (
    <div className="loading-container">
      <div className="loading-area">
        <span>T</span>
        <span>o</span>
        <span>s</span>
        <span>a</span>
        <span>y</span>
        <span>o</span>
        <span>b</span>
        <span>i</span>
        <span>.</span>
        <span>.</span>
      </div>
    </div>
  ) : null;
}

export default Loading;
