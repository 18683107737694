import React from 'react';
import styled from 'styled-components';
import img11 from '../../img/11.jpg';
import Access from '../MainContents/Access';
import DocumentRequest from '../MainContents/DocumentRequest';
const Top = styled.div`
  text-align: -webkit-center;
  margin: auto;
  margin-top: 170px;
  width: 90%;
`;

function Schedule() {
  
  return (
    <Top>
      <img src={img11} alt="" />
      <DocumentRequest />
      <Access />
    </Top>
  );
}

export default Schedule;