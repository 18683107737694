import React from 'react';
import styled from 'styled-components';

const DivTop = styled.div`
    width: 100%;
    margin: auto;
    margin-top: 100px;
`;
const DocumentRequest = () => {
    return (
        <DivTop>
            <div className='text-center'>
                <div className="lg:-mt-2 mb-4 inline-flex items-center">                 
                    <a href="https://tosayobi.ac.jp/mail/mail.php" target='_blank'>
                        <div className='transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-regal-hover-green duration-300 border-solid rounded border p-5 pr-20 pl-20 text-white' style={{background: '#9b003f'}}>
                            <h3 className='text-3xl'>資料請求</h3>
                            <p className='mt-2'>最新の資料はこちらから</p>
                        </div>
                    </a>
                    
                </div>
            </div>
        </DivTop>
    );
}

export default DocumentRequest;