import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Course from './Course';
import { device } from '../MainContents/Style/StyleDevice';
import topImagePc from "../../img/image_top3.jpeg";
import topImageSp from "../../img/image_top3.jpeg"; // スマホ用の画像をインポート
const DivTop = styled.div`
  margin-top: 170px;
  @media ${device.mobile} {
    margin-top: 80px;
  }
`;

const HeadingTitle = styled.div`
  text-align: left;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.5em;
  color: #9b003f;
  margin-bottom: 30px;
  @media ${device.mobile} {
    font-size: 26px;
    margin-bottom: 10px;
    padding-left: 20px;
    padding-top: 18px;
  }
`;



const IntroductionsMains = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const imageSrc = windowWidth <= 768 ? topImageSp : topImagePc; // 768pxをスマホとパソコンの画像切り替えの基準に

  return (
    <>
    <DivTop>
      <div className="relative">
        <img src={imageSrc} alt="Top Image" className="w-full opacity-40" />
        <HeadingTitle className="mb-5 absolute tracking-widest p-4" style={{ left: '10%', top: '15%' }}>
          入れる大学よりも<br />
          入りたい大学へ<br />
        </HeadingTitle>

        <div className="hidden md:block absolute  p-4" style={{ left: '10%', top: '45%' }}>
            <p className="text-xl tracking-widest font-bold">TOSAYOBIはクラス授業と個人添削を通して、</p>
            <p className='text-xl tracking-widest font-bold mt-3'>受験生とともに走り続けます。</p>
        </div>
      </div>
      <div className="block md:hidden p-5">
            <p className="text-xl tracking-widest font-serif">TOSAYOBIはクラス授業と個人添削を通して、受験生とともに走り続けます。</p>
      </div>

      <div>
      <section className="text-gray-600 body-font">
            <div className="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
                <div className="lg:flex-grow w-full lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-red-700">
                    2コース・12クラス専任教員が
                    <br className="hidden lg:inline-block" />責任をもって指導します！
                </h1>
                <p className="mb-8 leading-relaxed text-black">
                    TOSAYOBIは、全体を国公立文系・理系と私立文系・理系の２コースに分け、さらにそれぞれのコースをレベル別に分けることで、全12クラスとしています。
                    専任教員がクラス主任となり、日常の学習から受験まで、責任を持って指導にあたります。 TOSAYOBIの専任教員は、浪人生のみに全力を注ぐことができる態勢となっています。
                    質問・相談・面談など、 昼休みや空き時間、放課後など、いつも浪人生のことを第一に動いています。TOSAYOBIには、高知市内、周辺の高校の卒業生だけではなく、
                    遠方の高校の卒業生や高校卒業認定の生徒も入学しています。 ともに励まし合い、競い合う仲間の輪が、出身校を越えて広がっています。
                </p>
                </div>

            </div>
        </section>
      </div>

      <Course />
    </DivTop>

    </>
  );
};

export default IntroductionsMains;
