import React from "react";
import styled from 'styled-components';


const Top = styled.div`
    margin-top: 50px;
    margin-bottom: 50px;
`;
const GoogleMapIframe = styled.iframe`
    filter: grennscale(1) contrast(1.2) opacity(0.4);
`;


const Access = () => {
    return (
        <Top>
            <div className="text-center">
                <h1 className="text-2xl font-bold text-red-700">
                <span class="material-symbols-outlined">location_on</span>
                    アクセスマップ
                </h1>

            </div>
                <div className="container px-5 mx-auto flex sm:flex-nowrap flex-wrap">

                    <div className="lg:w-1/3 md:w-1/2 bg-white flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0">
                        <div className="p-3">
                            <h2 className="text-gray-900 text-lg mb-1 font-medium title-font">JR高知駅から徒歩５分と最高の交通アクセス！！</h2>
                            <p className="leading-relaxed text-gray-600">本校は県内唯一の学校法人の予備校です。</p>
                            <p className="leading-relaxed mb-5 text-gray-600">公共交通機関の学割制度を利用できるので、経済的に負担も大幅に軽減できます。</p>
                        </div>
                    </div>
                        <div className="w-full  rounded-lg overflow-hidden sm:mr-10 p-2 flex items-end justify-start relative h-72">
                            <GoogleMapIframe width="100%" height="100%"  title="map" marginheight="0" marginwidth="0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d207.78816263823035!2d133.5443195739907!3d33.56349223937818!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f140!3m3!1m2!1s0x354e194bb06afcdd%3A0xde8a0659bdee3cb6!2z5Zyf5L2Q5aG-5LqI5YKZ5qCh!5e0!3m2!1sja!2sjp!4v1696904551689!5m2!1sja!2sjp"></GoogleMapIframe>
                        </div>
                </div>
        </Top>
    );
}

export default Access;