import React from 'react';
import styled from 'styled-components';
import { device } from '../MainContents/Style/StyleDevice';
import test13 from "../../img/test14.png";
import lifeTec from "../../img/info_note.png";
import haikei from "../../img/support.jpeg";

const DivTopTest = styled.div`
    // width: 100%;
    // margin: auto;
    // margin-top: 10px;
    // background: url(${haikei}) repeat center top;
    // background-size: cover;
    // padding: 50px 20px 40px;
    margin-top: 170px;
    @media ${device.mobile} {
      margin-top: 80px;
    }
`;

const HeadingTitle = styled.div`
  text-align: left;
  font-size: 40px;
  font-weight: 500;
  line-height: 1.5em;
  color: #9b003f;
  margin-bottom: 30px;
  @media ${device.mobile} {
    font-size: 20px;
    margin-bottom: 10px;
    padding-left: 0px;
    padding-top: 18px;
  }
`;
const ImgStudentBackUp = styled.img`
    width: 50%;
    margin:30px auto 10px;
    @media ${device.mobile}{
        width: 100%;
   }
`
const MessagePsub = styled.p`
  text-align: center;
  color: #9b003f;
  font-size: 27px;
  font-weight: bold;
  letter-spacing : 0.32em;

  @media ${device.mobile}{
    font-size: 22px;
    padding: 1px;
   }
`;

const SupportTitle = styled.p`
    color: #9b003f;
    font-weight: bold;
    margin-top: 30px;
`
const SupportTitleSpan = styled.span`
   font-size: 27px;
   padding-left: 6px;
   letter-spacing : 0.1em;
`
const ImgLife = styled.p`
   max-width: 30%;
   @media ${device.mobile}{
    max-width: 100%;
   }
`

const Support = () => {
    return(
    <DivTopTest>
        <div className="relative">
        <img src={haikei} alt="Top Image" className="w-full opacity-40" />
        <HeadingTitle className="absolute tracking-widest p-4" style={{ left: '10%', top: '10%' }}>
          独自のサポートシステムで<br />
          生徒をバックアップ！<br />
        </HeadingTitle>
        </div>


        {/* <MessagePsub>独自のサポートシステムで生徒をバックアップ！</MessagePsub> */}
        <ImgStudentBackUp src={test13} alt="Top Image" className="w-full" />
        <div className="w-11/12 mx-auto p-6 bg-white border-4 border-red-200 shadow">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-black">
                予備校
            </h5>
            <SupportTitle><span className="material-symbols-outlined text-xl">desk</span><SupportTitleSpan>自習室</SupportTitleSpan></SupportTitle>
            <p className="mb-3 font-normal text-gray-700">
            月曜から土曜までは8時から20時まで、日曜日は9時から16時まで利用できます。 個室ブース形式と教室形式の予備校生専用の自習室があり、集中して勉強に取り組むことができます。
            </p>

            <SupportTitle><span className="material-symbols-outlined text-sm">edit_note</span><SupportTitleSpan>個別添削</SupportTitleSpan></SupportTitle>
            <p className="mb-3 font-normal text-gray-700">
            一人ひとりのレベルに応じた添削指導を実施しています。それぞれの学習レベルを把握し、基礎の徹底から応用力の錬成まで各教科担当が責任をもって指導します。
            </p>

            <SupportTitle><span className="material-symbols-outlined text-sm">description</span><SupportTitleSpan>ICT教材</SupportTitleSpan></SupportTitle>
            <p className="mb-3 font-normal text-gray-700">
            <span className="font-bold text-lg">「情報Alドリル」</span> 令和7年度共通テストから導人される情報Iに対応した教材です。Alが一人ひとりの弱点を特定し、苦手箇所を反復学習する個別最適化された学習ができます。
            </p>
            <ImgLife><a href="https://juku.lifeistech-lesson.jp/"><img src={lifeTec} alt="" /></a></ImgLife>

            <p className="mb-3 font-normal text-gray-700">
            <span className="font-bold text-lg">「速読聴英語」(オプション)</span> 英語長文中の「単語学習」　「演習」を行ったあと、速読や倍速音声トレーニングによるスヒード強化を行うことでバランスよくリーディングカとリスニングカの向上を目指すことができる教材です。
            </p>

            <SupportTitle><span className="material-symbols-outlined text-sm">contract_edit</span><SupportTitleSpan>小論文</SupportTitleSpan></SupportTitle>
            <p className="mb-3 font-normal text-gray-700">
            5月から始まる小論文の授業では、近年の出題テーマに合わせた題材を用い、一人ひとり丁寧に答案を添削し、解答例もつけて返却します。国公立大学の前期試験までにおよそ30本ほどの小論文作成をします。
            </p>

            <SupportTitle><span className="material-symbols-outlined text-sm">school</span><SupportTitleSpan>全国模試</SupportTitleSpan></SupportTitle>
            <p className="mb-3 font-normal text-gray-700">
            進研模試/ベネッセ・駿台模試(マーク式3回・記述式2回)、河合塾全統模試(マーク式2回・記述式3回) の採用を予定しています。
            </p>


            <h5 className="mt-5 mb-2 text-2xl font-bold tracking-widest text-black">
                保護者会
            </h5>
            <p className="mb-3 font-normal text-gray-700">
            本校では、前期(7月)と後期(12月)に生徒・保護者・クラス担任による三者面談を実施しています。志望校の合否の可能性だけでなく、授業の出席状況や普段の予備校生活について情報を共有します。
            </p>

            <h5 className="mt-5 mb-2 text-2xl font-bold tracking-widest text-black">
                高等学校との連携
            </h5>
            <p className="mb-3 font-normal text-gray-700">
            出身校の先生方とお会いして本校での学習の成果・出席率・生活態度などについて報告・相談をしています。
            </p>

        </div>

    </DivTopTest>
    
    );
}

export default Support;