import React, { useRef } from "react";
import { device } from '../MainContents/Style/StyleDevice';
import SuccessList from "./SuccessList";
import ImgSuccessList from '../../img/successList.jpg';
import styled from "styled-components";
const DivTop = styled.div`
    margin: auto;
    margin-top: 170px;
    width: 90%;
  @media ${device.mobile} {
    margin-top: 80px;
    width: 95%;
  }
`;
const Iframe = styled.iframe`
    width: 100%;
    height :400px;
    overflow: auto; /* スクロールバーを非表示 */
`;


function Success() {
  
  return (
    <DivTop>
        <div className="mt-10 mb-0">
            <img src={ImgSuccessList} alt="合格実績一覧" width={'90%'}/>
        </div>
        {/* <div class="table__container js-scrollable">
            <Iframe src={'https://tosayobi.ac.jp/Success/'}></Iframe>
        </div> */}
        <hr />
        <h3 className="mt-3 text-xl text-pink-400 font-bold"><span className="pr-3 align-middle"><span class="material-symbols-outlined">
comment
</span></span>2024年春　合格体験記</h3>
        <div>
            {/* 国立 2024年 STR */}
            <SuccessList 
                college={'筑波大'} college_faculty={'理工学群'} school={'高知学芸高校'} name={'I・K'}
                comment={'私は現役生時代，筑波大学には到底及ばなく一年間の猛勉強をし，なんとか共通テストのボーダーを超え試験を受ける覚悟を決めました。浪人しているときに，模試での失敗や受験への不安もありましたが、それらをともにTOSAYOBIで浪人している仲間たちと支えあい，励ましあって乗り越え成長しました。また，この１年で困難に立ち向かい、諦めずに努力すれば目標を達成できることをTOSAYOBIで経験できました。浪人の一年はつらく，長く感じるかもしれませんが，実りある１年になるように頑張ってください。'}
            />

            <SuccessList 
                college={'東京農工大'} college_faculty={'農学部'} school={'高知追手前高校'} name={'T・N'}
                comment={'私はTOSAYOBIに1年間通うことでしっかりと基礎を学び直し，高校生の時から志望していた東京農工大に合格することができました。私は授業に欠席することもあまりなかったのですが，共通テストで少し失敗をし，自己採点での志望校の判定はCでした。それでも二次試験まで得意科目である生物の添削や他の教科の授業をしっかり受け，必死にやったことでなんとか合格できました。コツコツと着実に努力を継続することの大切さをTOSAYOBIの1年間で学びました。'}
            />

            <SuccessList 
                college={'京都工芸繊維大'} college_faculty={'工芸科学部'} school={'高知学芸高校'} name={'Y・Y'}
                comment={'私は，TOSAYOBIでの浪人の一年間において，基礎の重要性と最後まで諦めないことの大切さを学びました。先生方による授業や添削指導によって，現役時代では疎かにしていた基礎固めを重点的に行うことができました。結果，次第に難しい問題にも耐性がつき，だんだん解けるようになりました。共通テスト後の自己採点の段階では第一志望を受験するには少し心配でしたが，最後まで努力し続けた結果，第一志望に合格することができました。'}
            />

            <SuccessList 
                college={'神戸大'} college_faculty={'国際人間科学部'} school={'高知追手前高校'} name={'I・Y'}
                comment={'私は大学に合格するために，特に英語の速読力に力を入れました。共通テストの英語は時間との勝負なので，どのくらい後半に時間を使えるかを意識しました。TOSAYOBIの速読聴に加え、速読英単語必修・上級編をそれぞれ4〜5周程度行いました。英語の速読力を身に付ける中で，英語だけでなく全ての教科に言えることは習慣付けが大事だということです。私は毎日寝る前に速読や暗記をし，アプリで達成記録をつけていました。そうすることで日常のバランスが保たれ，受験が近づいてきても精神的な安定に繋がったと思います。'}
            />

            <SuccessList 
                college={'大阪公立大'} college_faculty={'文学部'} school={'土佐塾高校'} name={'T・M'}
                comment={'TOSAYOBIに入る前は，現役の頃は一応きちんと勉強していたつもりでいたし，予備校の授業は演習ばかりだと思っていましたが，実際はどの科目の先生もしっかりと基礎から授業をしてくださいました。授業以外に添削指導もしてもらいましたが，英語は基礎文法からやらせてもらえたので改めて初心に帰って勉強をやり直すことができました。周りがやっている勉強は気にせず，自分がやるべきことや穴をみつけて補完していくという作業が大事です。自分はこれでいいのだと自信を持って頑張ってください。'}
            />

            <SuccessList 
                college={'宇都宮大'} college_faculty={'地域デザイン科学部'} school={'高知学芸高校'} name={'M・R'}
                comment={'現役の頃は勉強や将来のことから逃げ続けてきました。浪人すると決まった時，これまで積極的に勉強することを避けていた理科と数学Ⅲをもう1回きちんとやり直そうと決めました。TOSAYOBIの一年間では，将来のことややりたいことなどを考える時間をゆっくりとれたため，勉強はしんどい時もあったけど頑張ってやり抜こうという気持ちになれました。明確でなくてもいいから少しでも自分の目標を決めることが一年頑張れる原動力になると思います。'}
            />

            <SuccessList 
                college={'奈良教育大'} college_faculty={'教育学部'} school={'高知西高校'} name={'T・H'}
                comment={'合格するためには，なんとなく理解するのではなく，理論的に理解することが大切だと思います。私は，この一年間，TOSAYOBIで学び直すことで，より深く理解することが出来ました。現役生の時に頑張っていた人ほど浪人生の一年は辛いと思います。不安なこともあるとは思いますが，浪人生として過ごした一年は長い目で見れば大したことなんてないと思える一年にしてください。'}
            />

            <SuccessList 
                college={'奈良教育大'} college_faculty={'教育学部'} school={'高知学芸高校'} name={'T・U'}
                comment={'現役生の時はすべてが中途半端で，共通テストでも点数がとれませんでした。浪人することが決まった時は悔しさもありましたが，気持ちを切り替えてTOSAYOBIで頑張ろうと決心し，休日も自習室で勉強したり，先生方に積極的に添削指導をお願いしました。共通テスト後の判定はいいとは言えなかったですが，先生方は私の意思を尊重してくださり，丁寧に二次試験対策をしてくださったので，前期で合格することができました。最後まで諦めないことが大切だと思います。先生方を頼って，自分を信じて頑張り抜いてください。'}
            />

            <SuccessList 
                college={'大阪教育大'} college_faculty={'教育学部'} school={'土佐高校'} name={'I・Y'}
                comment={'現役の時の共通テストは壊滅的な点数だったので，TOSAYOBIに入学した当初は授業についていけるかな…と不安でした。ですが，TOSAYOBIの先生方は簡単なところから丁寧に教えてくださったので，音楽の勉強との両立も無理なく着実に学力を伸ばしていくことができました。 時間の流れは思っていたよりも早く過ぎていき，あっという間に一年が経ちました。合格した時の喜びは一生忘れないと思います。TOSAYOBIを選んで良かったです。'}
            />

            <SuccessList 
                college={'岡山大'} college_faculty={'工学部'} school={'高知学芸高校'} name={'H・K'}
                comment={'私がTOSAYOBIに通って一番良かったと思うことは，生活習慣の固定ができたことです。朝8時から夜8時までの12時間自習室を利用することができたので，授業と授業の合間や放課後に自習をすることができました。1週間のカリキュラムでも授業に適度な合間があることや高知駅から近くにあるため，一年間あまり苦にならず通うことができました。現役の頃と生活習慣を変えることなく過ごせたことが合格の主な要因だと思います。最後まで諦めず続けることができれば必ず第一志望に合格できると思います。'}
            />

            <SuccessList 
                college={'香川大'} college_faculty={'医学部（臨床心理）'} school={'山田高校'} name={'T・Y'}
                comment={'私は地元に貢献するために，高校時代から医師になることを目標として勉強していましたが，TOSAYOBIで浪人している間に心理学に興味を持ちました。そして，二次試験対策は、志望大学の過去問を繰り返し解きました。試験当日のように時間を決めて過去問を解く練習を繰り返したことで本番でも落ち着いて問題を解けました。進路に関して困ったときは一人で考え込まず，早いうちにTOSAYOBIの先生方に相談することが大事だと思います。'}
            />

            <SuccessList 
                college={'徳島大'} college_faculty={'医学部（放射線技術）'} school={'高知西高校'} name={'O・I'}
                comment={'私は，TOSAYOBIでの一年間，数学の添削指導を受けてから自分の答案を客観的に見られるようになりました。答案を書く練習を繰り返すことで本番では落ち着いた気持ちで実力を出すことができました。また，先生方には学習面はもちろん，精神面で手厚くサポートしていただきました。浪人生活は人によっては病むこともあると思いますが，TOSAYOBIの先生方は心強い味方となってくれます。'}
            />

            <SuccessList 
                college={'徳島大'} college_faculty={'理工学部'} school={'高知小津高校'} name={'F・K'}
                comment={'現役の時に合格していた私立大学に入学するかとても悩みましたが，今年一年，TOSAYOBIで頑張れてよかったです。文系科目が苦手でとても苦労しましたが，授業中の先生の発言をメモして復習することで徐々に点数が伸びていきました。授業後は自習室に残って勉強に取り組みました。いつも残っている人たちと高校は違いましたが仲良くなり，一年間楽しい時間を過ごせました。TOSAYOBIでは5教科を満遍なく学ぶことができるので，苦手科目があったり，成績に偏りのある人にはオススメします。'}
            />

            <SuccessList 
                college={'徳島大'} college_faculty={'理工学部'} school={'高知小津高校'} name={'T・G'}
                comment={'合格の最大のポイントは，モチベーションを一定に保ちながら一ヶ月くらいで出来そうな目標を立ててやり続けることだと思います。自分の苦手な科目や得意な科目の勉強の配分を考えて勉強することが非常に重要だと受験を通して感じました。実際に計画を立てて行動してみるとその時間内で理解したかった範囲が終わらないなんてことも多々あるので，その都度やり方や時間が足りないなら費やす時間の配分などを考え直して実行したりして最後まで私は頑張り抜きました。私は計画をある程度立てていないと時間の掛け方や好きな科目ばかりやってしまうなどに難があったので同じ様な方にはオススメできる勉強法です。'}
            />

            <SuccessList 
                college={'高知大'} college_faculty={'人文社会科学部'} school={'高知西高校'} name={'F・H'}
                comment={'日曜日以外基本的に予備校に行くため最初は負担を感じるかもしれませんが，TOSAYOBI生全員の意識が高いので，常に向上心を持ち勉強に励むことができる環境が整っています。そして一つ一つの授業を大切にし、復習や添削に取り組むことが私は一番大事だと感じました。また、TOSAYOBIには一人一人の生徒に寄り添ってくれる先生方がたくさんいます。一人きりで頑張るのではなく，積極的に先生方や友達を頼りにし，自分の学力を伸ばしていってほしいです。'}
            />

            <SuccessList 
                college={'高知大'} college_faculty={'教育学部'} school={'高知追手前高校'} name={'K・Y'}
                comment={'TOSAYOBIでは現役のときに選択していなかった倫理や，苦手だった理科基礎の点数を伸ばすことができました。私は記憶力がよくなかったので覚える言葉に関連する情報も取り入れるようにしました。倫理は直前まで過去問の選択肢と資料集を読んで，間違った問題はすぐに資料集で確認しました。理科基礎も授業の話をできるだけメモにとるようにし，問題演習の日は2時間くらいかけて資料を一周し，緊張感をもって問題を解くよう心がけました。'}
            />

            <SuccessList 
                college={'鹿屋体育大'} college_faculty={'体育学部'} school={'高知追手前高校'} name={'O・T'}
                comment={'TOSAYOBIで浪人生活を送った一年間，合格する上で大事だと思った事を伝えます。共通テストですが，できるだけ7割を目指してやれる勉強はしっかりやってください。勉強方法などは担当の先生と相談したうえで自分に合った方法を見つけることが大事です。二次試験の実技試験とプレゼンですが、共通テストで点を採れているなら、実技試験は運動能力検査がいいのかなと思います。プレゼンは質疑応答に対してしっかり答える対策が大事です。私はクラス主任の先生と何度もプレゼンの練習をしました。どれだけ他の受験生と内容に差別化できるかがポイントになると思います。'}
            />
            {/* 国立 2024年 END */}

            {/* 公立 2024年 STR */}
            <SuccessList 
                college={'埼玉県立大'} college_faculty={'保健医療福祉学部'} school={'高知学芸高校'} name={'T・M'}
                comment={'私はこの一年間，特に理系科目において理論的に考える力を養えました。授業を大切にし，添削や自習室を活用し，TOSAYOBIの仲間と先生方の力を借りながら一年間頑張りました。勉強するにあたって客観的に自分を見て，さらに自分を高めていくためにどうするべきかを考えていました。TOSAYOBIで得られた知識と自信は，共通テストと二次試験に自信をもって挑めることにつながりました。'}
            />

            <SuccessList 
                college={'山口県立大'} college_faculty={'看護栄養学部'} school={'高知南高校'} name={'S・K'}
                comment={'私がまずTOSAYOBIに入学して取り組んだことは基礎固めです。現役の頃は基礎レベルは改めて学びなおす必要はないと考えていましたが，TOSAYOBIに入学し授業を受けていると，ところどころ抜けている内容があったり，思い違いをしていることがあることに気づき，基礎がまだまだ足りていないと思い知りました。そこで夏が終わるまでに基礎固めをしっかりしようと授業や添削指導に取り組みました。基礎がしっかり定着したからこそ，後期授業の入試演習につながり，最終的には無事，第一志望に合格することができました。'}
            />

            <SuccessList 
                college={'高知工科大'} college_faculty={'理工学群'} school={'高知追手前高校'} name={'I・N'}
                comment={'TOSAYOBIでは先生方が親身になって話を聞いてくれ，多教科個別に添削指導もしてくれます。質問し理解できるまで答えてくれます。私は現役時代，家では全く勉強をしなかったので，浪人してからは毎日授業が終わった後，自習室に残って勉強をし，質問があれば何度も職員室に通いました。大事なことは自分の1日の勉強サイクルを崩さないこと，授業の復習をしっかりやることです。いい意味でTOSAYOBIの先生をこき使ってください。'}
            />
            <SuccessList 
                college={'高知工科大'} college_faculty={'理工学群'} school={'高知小津高校'} name={'K・R'}
                comment={'自習室に入ると必ず誰かが勉強していてみんなが集中しているので，自分もサボることなく最後まで頑張ることができたことが，第一志望に合格できた要因だと思います。授業だけで自分の苦手分野を克服するのは難しいので，いかに隙間時間をうまく使うかが合否に左右すると思います。また，現役のころと違いからだを動かす機会も少なくなるので，体調管理などにも気をつけてください。'}
            />

            <SuccessList 
                college={'高知県立大'} college_faculty={'文化学部'} school={'土佐塾高校'} name={'Y・S'}
                comment={'第一志望の大学に入学する！と決めてから，合格することを信じて突き進みました。私は英語の添削を継続して続けることを特に意識しました。結果がすぐには出なくて初めはしんどいですが，諦めずに継続することを学べました。このしんどさを切り抜けることができたのは，TOSAYOBIの先生方が手厚くサポートしてくれたからです。おかげで第一志望に合格することができました。'}
            />

            <SuccessList 
                college={'高知県立大'} college_faculty={'文化学部'} school={'高知西高校'} name={'O・H'}
                comment={'この一年間，勉強は大変でしたが，TOSAYOBIの先生方に協力をいただきながら一つ一つ壁を乗り越えてきました。私はこの一年間，一貫して「目標を見失わない，失敗を恐れない」という気持ちを持ち続けてきました。また，他人に頼ることの大切さも学ぶことができました。他人の意見を聞いて取り入れることで，自分の意見に深みが出てくると考えます。これから先もTOSAYOBIで学んだことを活かして成長していきたいです。'}
            />

            {/* 公立 2024年 END */}

            {/* 私立 2024年 STR */}
            <SuccessList 
                college={'法政大'} college_faculty={'法学部'} school={'土佐高校'} name={'Y・S'}
                comment={'現役時代に知識が不十分だった私にとって，毎日・毎時間休まずにTOSAYOBIの授業に出続けたことがよかったと思います。何より，浪人の一年間では自分と向き合う時間が多いので，どれだけ受験当日に自分の最大限の力を発揮できるかに拘って勉強することが大切だと思います。一年間ずっと勉強し続けるのはとてもきつかったですが，TOSAYOBIで一年間浪人を経験したことは絶対自分の人生にとってプラスになると思います。TOSAYOBIは添削や個別面談など，浪人生を全面的にサポートしてくれるので，みなさんも頑張ってください。'}
            />

            <SuccessList 
                college={'同志社大'} college_faculty={'スポーツ健康科学部'} school={'土佐高校'} name={'M・R'}
                comment={'私が浪人して思ったことは，自分が想像しているよりも成績を伸ばすことは難しいということです。私は自習室で毎日勉強をしていましたが，模試の成績はすぐには上がらず，大変苦しい思いをしました。こうならないようにこれから浪人しようと考えている人は，まず自分に合った勉強を見つけるために先生方に相談してみてください。睡眠時間も大事なのでうまく勉強時間とのバランスをとれるようにしてください。'}
            />

            <SuccessList 
                college={'神戸学院大'} college_faculty={'法学部'} school={'高知商業高校'} name={'Y・A'}
                comment={'高校時代は周りの友達は推薦で合格している中，私は一人だけ一般入試を受け続けていました。私だけ進学先が決まらないまま卒業式を迎え落ち込んでいましたが，もう一年だけ頑張ろうとTOSAYOBIで浪人することを決意しました。同じ学校の生徒が誰もおらず少し心配でしたが，先生方が話しかけてくれたり，分からないところを授業が終わった後もしっかり教えてくれました。この一年を通して本当に多くのことを学べて，私はTOSAYOBIで浪人してよかったと感じました。'}
            />
            {/* 私立 2024年 END */}
        </div>
    </DivTop>
  );
}

export default Success;
